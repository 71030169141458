@import "../../style/variables";

nav {
  a,
  a:link,
  a:visited,
  a:focus,
  a:hover,
  a:active {
    text-decoration: none;
    text-underline: none;
    border: none;
  }
}